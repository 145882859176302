import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import {
  Row,
  Col,
  Container,
  Divider,
  Title,
  Box,
  Image,
} from '@firsttable/web-components/atoms';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import { MemberCard } from '@firsttable/web-components/molecules';
import { Swiper as Reviews } from '@firsttable/web-components/organisms';
import GenericLayout from '../layouts/GenericLayout';
import SEO from '../components/seo';
import parseHtml from '../helpers/parseHtml';
// @ts-ignore
import ourVision from '../images/our-vision.svg';
// @ts-ignore
import ourStartup from '../images/our-startup-story.svg';
// @ts-ignore
import whyWeStarted from '../images/why-we-started.svg';
// @ts-ignore
import weStandFor from '../images/what-we-stand-for.svg';
import usePageRehydrate from '../hooks/usePageRehydrate';
import NavLink from '../components/atoms/NavLink';

const blocks: any = {
  whyWeStarted,
  weStandFor,
  ourStartup,
  ourVision,
};

const teamMembers = [
  {
    name: 'Mat',
    position: 'CEO and Founder',
    gender: 'male',
  },
  {
    name: 'Blair',
    position: 'General Manager',
    gender: 'male',
  },
  {
    name: 'Jess',
    position: 'Operations Manager',
    gender: 'female',
  },
  {
    name: 'Laura',
    position: 'Marketing and Communications Manager',
    gender: 'female',
  },
  {
    name: 'Gene',
    position: 'Lead Developer',
    gender: 'male',
  },
  {
    name: 'Michael',
    position: 'Developer',
    gender: 'male',
  },
  {
    name: 'Mandy',
    position: 'NSW Sales Director',
    gender: 'female',
  },
  {
    name: 'Jessie',
    position: 'Territory Manager',
    gender: 'female',
  },
  {
    name: 'Cam',
    position: 'Territory Manager',
    gender: 'male',
  },
  {
    name: 'Elliott',
    position: 'Business Development',
    gender: 'male',
  },
  {
    name: 'Jackie',
    position: 'Business Development',
    gender: 'female',
  },
  {
    name: 'Bryan',
    position: 'App Developer',
    gender: 'male',
  },
  {
    name: 'Peter',
    position: 'Business Development',
    gender: 'male',
  },
  {
    name: 'Elizabeth',
    position: 'Business Development',
    gender: 'female',
  },
  {
    name: 'Stuart',
    position: 'Recruitment Manager',
    gender: 'male',
  },
  {
    name: 'Faye',
    position: 'Customer Support',
    gender: 'female',
  },
  {
    name: 'William',
    position: 'Customer Support',
    gender: 'male',
  },
  {
    name: 'Timmy',
    position: 'Customer Support',
    gender: 'male',
  },
];

const TeamContent = ({ data }: { data: any }) => (
  <>
    <Container display={[null, 'none']} mb="xl">
      <Divider />
    </Container>
    <Title textAlign="center" size="h1">
      Our Team
    </Title>
    <Container maxWidth={1100} mt={['xl', '60px']} mb={['xl', '60px']}>
      <Row gap={[10, 0]} mb={-30} justifyContent="center">
        {teamMembers.map(({ name, position, gender }) => (
          <Col key={name} width={[1 / 2, 1 / 2, 1 / 3]} mb="xxl">
            <MemberCard
              NavLinkComponent={NavLink}
              name={name}
              data={
                data[name.toLowerCase()]
                  ? data[name.toLowerCase()].childImageSharp.fixed
                  : data[gender].childImageSharp.fixed
              }
              post={position}
            />
          </Col>
        ))}
      </Row>
    </Container>
  </>
);

TeamContent.propTypes = {
  data: PropTypes.shape({}),
};

const Mission = ({ data }: { data: any }) => {
  const ourStoryData: any = usePageRehydrate(get(data, 'ourStory'));
  return (
    <GenericLayout>
      <SEO
        id={get(ourStoryData, 'foreignId')}
        title={get(ourStoryData, 'metaTitleFormatted')}
        description={get(ourStoryData, 'metaDescription')}
        ogImage={get(ourStoryData, 'ogImage')}
        metaTags={get(ourStoryData, 'metaTags')}
        slug="/our-story/"
      />
      <ContainerContent>
        <RowHead
          title="Our Story"
          subTitle="We want to share our love of dining out with you. It’s simple."
          birdBookIcon
        />
        {get(ourStoryData, 'blocks.edges').map(({ node }: any, index: any) => (
          <MissionBlock
            key={node.id}
            title={node.title}
            position={index % 2 === 0 ? 'right' : 'left'}
            image={blocks[node.key]}
          >
            {parseHtml(node.content || '')}
          </MissionBlock>
        ))}
      </ContainerContent>
      <Box mb={['xl', 0]}>
        <Reviews reviews={data.homeReviews.edges} />
      </Box>
      <TeamContent data={data} />
    </GenericLayout>
  );
};

const MissionBlock = ({
  title,
  children,
  position,
  image,
}: {
  title: string;
  children: any;
  position?: string;
  image: any;
}) => {
  const BlockImage = () => (
    <Image maxWidth={['100%', 460]} src={image && image} />
  );

  return (
    <>
      <Row gap={40} mt={['m', 80]} mb={['m', 80, 120]} alignItems="center">
        {position === 'left' && (
          <Col width={[1, 1, 1 / 2]} textAlign="center" mb={['l', 0]}>
            <BlockImage />
          </Col>
        )}
        <Col width={[1, 1, 1 / 2]} order={[1, 0]}>
          <Box maxWidth={[null, 460]} mx="auto">
            <Title textAlign={['center', 'left']}>{title}</Title>
            {children}
          </Box>
        </Col>
        {position === 'right' && (
          <Col width={[1, 1, 1 / 2]} textAlign="center" mb={['l', 0]}>
            <BlockImage />
          </Col>
        )}
      </Row>
      <Divider display={[null, 'none']} mb="xl" />
    </>
  );
};
MissionBlock.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  children: PropTypes.any,
  position: PropTypes.string,
};
MissionBlock.defaultProps = {
  position: 'right',
};

Mission.propTypes = {
  data: PropTypes.shape({}),
};
Mission.defaultProps = {};

export default Mission;

export const query = graphql`
  query getOurStoryData {
    ourStory: globalPage(slug: { eq: "/our-story/" }) {
      ogImage
      metaTags
      foreignId
      metaTitleFormatted
      metaDescription
      blocks {
        edges {
          node {
            id
            title
            content
            sort
            key
          }
        }
      }
    }
    mat: file(relativePath: { eq: "team/mat-weir.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cam: file(relativePath: { eq: "team/cam.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    elliott: file(relativePath: { eq: "team/elliott.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bryan: file(relativePath: { eq: "team/bryan.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    elizabeth: file(relativePath: { eq: "team/elizabeth.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    faye: file(relativePath: { eq: "team/faye.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jackie: file(relativePath: { eq: "team/jackie.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    peter: file(relativePath: { eq: "team/peter.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stuart: file(relativePath: { eq: "team/stu.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    william: file(relativePath: { eq: "team/william.jpg" }) {
      childImageSharp {
        fixed(width: 272, height: 360, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gene: file(relativePath: { eq: "team/gene.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jessie: file(relativePath: { eq: "team/jessie.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jess: file(relativePath: { eq: "team/jess.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    laura: file(relativePath: { eq: "team/laura.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    michael: file(relativePath: { eq: "team/michael.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mandy: file(relativePath: { eq: "team/mandy.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    blair: file(relativePath: { eq: "team/blair.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    timmy: file(relativePath: { eq: "team/timmy.jpg" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    male: file(relativePath: { eq: "placeholders/male.png" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    female: file(relativePath: { eq: "placeholders/woman.png" }) {
      childImageSharp {
        fixed(width: 544, height: 720, quality: 70, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allAboutUsPage(filter: { slug: { eq: "/about-us/our-story/" } }) {
      edges {
        node {
          slug
          title
          parentId
          metaTitleFormatted
          metaDescription
          menuTitle
          metaTags
          id
          foreignId
          content
          className
        }
      }
    }
    homeReviews: allReviewsJson {
      edges {
        node {
          id
          name
          comment
          role
          restaurant {
            title
          }
        }
      }
    }
  }
`;
